<template>
  <div>
    <div style="margin-bottom: 10px">
      <a-form ref="searchFormRef" layout="inline" :model="searchForm">
        <a-form-item label="">
          <a-input v-model:value="searchForm.customerName" placeholder="请输入客户姓名" />
        </a-form-item>
        <a-form-item label="">
          <a-input v-model:value="searchForm.mobile" placeholder="请输入电话" />
        </a-form-item>
        <a-form-item label="">
          <a-date-picker show-time placeholder="提醒时间" format="YYYY-MM-DD" @change="timeChange" />
        </a-form-item>
        <a-form-item label="">
          <a-space>
            <a-button :loading="loading" type="primary" @click="searchData">
              查询
            </a-button>
          </a-space>
        </a-form-item>
      </a-form>
    </div>
    <a-table :columns="columns" :row-key="(record) => record.id" :pagination="pagination" :data-source="listData"
      :loading="loading" bordered size="small" :rowKey="(record, index) => { return index }"
      :scroll="{ y: 500 }" @change="handleTableChange">
      <template #sort="{ index }">
        {{index+1}}
      </template>
      <template #customerName="{ record }">
        <span>
          <a @click="onDetail(record)">
            <span v-if="record.customerName !== ''">{{ record.customerName }}</span>
            <span v-else> 无</span>
          </a>
        </span>
      </template>
    </a-table>
  </div>
  <a-drawer title="详情" width="80%" placement="right" v-model:visible="detailShow" destroyOnClose>
    <CustomerDetail v-model:customerId="customerId" :type="type" num="6"/>
  </a-drawer>
</template>

<script>
import { reactive, toRefs, ref, watch, onMounted } from 'vue'
import { privateRemindPage } from '@/api/dataCenter/kanban'
import CustomerDetail from '../../crmManagement/tool/customerDetail/index'
export default {
  components: {
    CustomerDetail
  },
  props: {
    remind: Object,
    customerClass: Number,
    orgId: String,
    saleId: String
  },
  setup (props) {
    const timeoutRef = ref(null)
    const state = reactive({
      loading: false,
      timeoutShow: false,
      detailShow: false,
      customerId: undefined,
      startProvinceId: '',
      title: '',
      type: undefined,
      listData: [],
      remindType: {},
      pagination: {
        current: 1,
        pageSize: 10,
        total: 0,
        showSizeChanger: true,
        showQuickJumper: true,
        showTotal: (total, range) => `共 ${total} 条`,
        onChange: (page, pageSize) => {
          state.pagination.current = page
          state.pagination.pageSize = pageSize
          loadData()
        }
      },
      org: undefined,
      sale: undefined,
      customerClassType: undefined,
      searchForm: {
        remindType: undefined
      },
      mirrorSearchForm: {},
      columns: [
        { title: '序号', width: 50, slots: { customRender: 'sort' } },
        {
          title: '客户名称',
          dataIndex: 'customerName',
          slots: {
            customRender: 'customerName'
          }
        },
        {
          title: '客户电话/微信',
          dataIndex: 'mobile'
        },
        {
          title: '提醒日期',
          dataIndex: 'remindDate'
        },
        {
          title: '意向等级',
          dataIndex: 'intentionLevel.label'
        },
        {
          title: '创建时间',
          dataIndex: 'createTime'
        },
        {
          title: '领取时间',
          dataIndex: 'enterPrivateTime'
        },
        {
          title: '业务员',
          dataIndex: 'saleName'
        },
        {
          title: '客户来源',
          dataIndex: 'customerSourceType.label'
        }
      ]
    })
    onMounted(() => {
      state.mirrorSearchForm = JSON.stringify(state.searchForm)
    })
    watch(() => props.remind, (newVal, oldVal) => {
      if (props.remind) state.remindType = props.remind
    })
    watch(() => props.orgId, (newVal, oldVal) => {
      if (props.orgId) state.org = props.orgId
    })
    watch(() => props.saleId, (newVal, oldVal) => {
      if (props.saleId) state.sale = props.saleId
    })
    watch(() => props.customerClass, (newVal, oldVal) => {
      if (props.customerClass) {
        state.customerClassType = props.customerClass
        state.type = (state.customerClassType - 1) + ''
      }
    })
    const setData = () => {
      if (props.searchFormData.year) state.searchForm.year = props.searchFormData.year
      if (props.searchFormData.yearMonth) state.searchForm.yearMonth = props.searchFormData.yearMonth
      state.searchFormData = props.searchFormData
    }
    const loadData = (current) => {
      if (current) {
        state.pagination.current = current
      }
      state.loading = true
      state.searchForm.remindType = state.remindType
      state.searchForm.orgId = state.org
      state.searchForm.saleId = state.sale
      state.searchForm.customerClass = state.customerClassType
      privateRemindPage({
        ...state.searchForm,
        current: state.pagination.current,
        size: state.pagination.pageSize
      }).then((res) => {
        if (res.code === 10000) {
          state.listData = res.data.records
          state.pagination.total = res.data.total
          state.pagination.pageSize = res.data.size
          state.pagination.current = res.data.current
        }
      }).finally(() => {
        state.loading = false
      })
    }
    const searchData = () => {
      loadData()
    }
    const resetData = () => {
      state.searchForm = JSON.parse(state.mirrorSearchForm)
      loadData()
    }
    const pickerChange = (e, v) => {
      state.searchForm.yearMonth = v
    }
    const openTimeOutData = record => {
      state.startProvinceId = Number(record.queryId)
      state.title = record.name + `(${state.searchForm.yearMonth})`
      timeoutRef.value.windowShow = true
      timeoutRef.value.searchForm.startAddress.province = record.queryId
      setTimeout(() => {
        timeoutRef.value.setData()
        timeoutRef.value.loadData()
      }, 10)
    }
    const onDetail = (e) => {
      state.detailShow = true
      console.log('state.type', state.type)
      state.customerId = e.customerId
    }
    // 表格数据页码改变事件
    const handleTableChange = (pag, filters, sorter) => {
      state.pagination.current = pag.current
      state.pagination.pageSize = pag.pageSize
      loadData()
    }
    const timeChange = (e, v) => {
      state.searchForm.remindDate = v
    }
    return {
      ...toRefs(state),
      timeoutRef,
      loadData,
      searchData,
      handleTableChange,
      onDetail,
      timeChange,
      resetData,
      setData,
      pickerChange,
      openTimeOutData
    }
  }
}
</script>

<style lang="less" scoped></style>
